<template>
  <div class="container">
    <!-- 请求完成前的加载效果 -->
    <div v-show="!loading">
      <div class="header-container" ref="home">
        <!-- 首页图片/标题切换 -->
        <div class="banner" ref="banner" @mouseenter="enterBanner" @mouseleave="leaveBanner">
          <!-- 左侧按钮 -->
          <a href="javascript:;" class="arrowl" ref="arrowl" :style="{display:datashow}">
            &lt;
          </a>
          <!-- 右侧按钮 -->
          <a href="javascript:;" class="arrowr" ref="arrowr" :style="{display:datashow}"> &gt; </a>
          <!-- 核心的滚动区域 -->
          <ul ref="ul">
            <!-- <li>
              <a href="#"><img src="../static/images/banner.png" alt=""></a>
            </li>
            <li>
              <a href="#"><img src="../static/images/aboutUs.png" alt=""></a>
            </li>
            <li>
              <a href="#"><img src="../static/images/placeholder.jpg" alt=""></a>
            </li> -->
            <li ref="li" v-for="(item,index) in allPics" :key="index"><img :src="item"></li>
          </ul>
          <!-- 小圆圈是根据图片张数动态生成 -->
          <ol class="circle" ref="circle">

          </ol>
          <!-- logo部分 -->
          <div class="header" ref="header">
            <div class="header-top">
              <el-row :class="[isScroll ? 'header  scroll ' : 'header']">
                <!-- 顶部导航栏 -->
                <el-col>
                  <div class="header-right">
                    <div class="logo">
                      <img style="margin-right:710px" src="../static/images/logo.png" alt="" />
                    </div>
                    <div class="div" @click="goAnchor('home', 50)" :class="[headerType == 'home' ? 'active' : '']">
                      首页
                    </div>
                    <div class="div" @click="goAnchor('product', 50)"
                      :class="[headerType == 'product' ? 'active' : '']">
                      产品展示
                    </div>
                    <div class="div" @click="goAnchor('successCase', 50)"
                      :class="[headerType == 'successCase' ? 'active' : '']">
                      成功案例
                    </div>
                    <div class="div" @click="goAnchor('news', 50)" :class="[headerType == 'news' ? 'active' : '']">
                      新闻资讯
                    </div>
                    <div class="div" @click="goAnchor('about', 50)" :class="[headerType == 'about' ? 'active' : '']">
                      关于我们
                    </div>
                    <div class="div" @click="goAnchor('contact', 50)"
                      :class="[headerType == 'contact' ? 'active' : '']">
                      联系我们
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品展示 -->
      <div class="product-container" :class="productAni" ref="product">
        <div class="title">
          <h1>产品展示</h1>
          <div class="success_rectangle"></div>
        </div>
        <div class="sort">
          <div v-for="(item, index) in classify" :key="index" :class="[productIndex == index? 'sortActive' : '']"
            @click="filterProduct(item, index)">
            {{ item.name }}
          </div>
        </div>
        <div class="product_show" v-show="product.length>0">
          <div class="product_show_left" @mouseenter="enterAllProduct(index)"
            @mouseleave="leaveAllProduct(index)">
            <div v-for="(item, index) in product" :key="index" @mouseenter="enterProduct(index)"
            @mouseleave="leaveProduct(index)">
            <div class="item_title" :class="[index == nowIntroduceIndex? 'itemTitle' : '']">{{ item.title }}
            </div>
          </div>
        </div>
        <div v-for="(item, index) in product" :key="index" @mouseenter="enterProduct(index)"
            @mouseleave="leaveProduct(index)">
            <!-- <div class="item_title" :class="[index == nowIntroduceIndex? 'itemTitle' : '']">{{ item.title }}
            </div> -->
            <div v-show="index == nowIntroduceIndex" class="productTitle">
              <img :src="item.imgSrc" alt="" />
            </div>
            <div class="product_info" v-show="index == nowIntroduceIndex">
              <div class="product_info_title">{{ item.title }}</div>
              <div class="product_info_introduce">{{ item.introduce }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 成功案例 -->
      <div class="successsCase-container" ref="successCase" :class="successCaseAni">
        <div class="title">
          <h1>成功案例</h1>
          <div class="success_rectangle"></div>
        </div>
        <div class="content" style="margin-top:60px;">
          <div class="success_box">
            <div class="item" v-for="(item, index) in caseList" :key="index">
            <div class="item-mask" v-show="index !== 7" @click="openDetail(item, 'successCase')"></div>
            <img :src="item.icon" alt="" v-show="index !==7">
            <div class="item_more"  v-show="index == 7" @click="openAllItem(index)">
              点击更多
              <img src="../static/images/more.png" alt="" style="width:13px;height:14px">
            </div>
            <div class="item-right" v-show="index !== 7" @click="openDetail(item, 'successCase')">
              <div>{{ item.title }}</div>
              <div class="item_introduce">{{ item.introduce }}</div>
            </div>
          </div>
          <div></div>
          </div>
        </div>
      </div>
      <!-- 新闻资讯 -->
      <div class="news-container" :class="newsAni" ref="news">
        <div class="title">
          <h1>新闻资讯</h1>
        </div>
        <div class="success_rectangle"></div>
        <div class="news_content">
          <div class="news">
            <div class="newsBox">
            <div @click="enter(0)">
              <!-- <img src="../static/images/new.png" alt="" /> -->
              <div :class="{ newsactive:isActive}" @click="isActiveNews">公司新闻</div>
              <!-- <div class="newsHover" v-show="newsType == 0">
                <div></div>
                <div></div>
              </div> -->
            </div>
            <div @click="enter(1)">
              <!-- <img src="../static/images/businessNews.png" alt="" /> -->
              <div :class="{news_industry:isIndustry}" @click="toggleActive">行业动态</div>
              <!-- <div class="newsHover" v-show="newsType == 1">
                <div></div>
                <div></div>
              </div> -->
            </div>
            </div>
          </div>
          <div class="companyItem">
            <div @click="openDetail(nowNews[0], 'news')">
              <img :src="nowNews.length != 0 ? nowNews[0].imgSrc : ''" alt="" />
              <div class="firstNews">
                <h4>{{ nowNews.length != 0 ? nowNews[0].title : "" }}</h4>
                <div>{{ nowNews.length != 0 ? nowNews[0].introduce : "" }}</div>
                <div style="width:455px;height:30px;line-height:30px">
                  <div>{{ nowNews.length != 0 ? nowNews[0].author : "" }}</div>
                  <div>{{ nowNews.length != 0 ? nowNews[0].date : "" }}</div>
                </div>
              </div>
            </div>
            <div>
              <div v-for="(item, index) in nowNews" :key="index" @click="openDetail(item, 'news')"
                class="news-otherItem">
                <div class="num"> {{ ((nowPage-1)*3+index +1) > 9 ? ((nowPage-1)*3+index +1) : "0"+((nowPage-1)*3+index +1) }}</div>
                <div style="width:455px;height:118px;margin-left: 36px;">
                  <h4 class="news_title">{{ item.title }}</h4>
                  <br />
                  <div class="news_introduce">{{ item.introduce }}</div>
                  <div>
                    <div style="float:left;">{{ item.author }}</div>
                    <div style="float:right;margin-left: 47px;">{{ item.date }}</div>
                  </div>
                </div>
              </div>
              <div class="page">
                <span @click="prevPage" v-show="nowPage != 1" style="margin-right: 30px">上一页</span>
                <span @click="nextPage" v-show="nowPage < totalPage && totalNumber > pageSize">
                  下一页
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="about-container" :class="aboutAni" ref="about">
        <h1 class="title">关于我们</h1>
        <div class="success_rectangle"></div>
        <div class="separator">
          <div class="about_bottom">
            <div class="abouts">
              <div class="sboutImg">
                <div class="row">
                  <div class="aboutImg">
                    <img :src="team.length > 0 ? team[0].avator : ''" alt="" :style="{ 'margin-left': x + 'px' }" />
                    <img :src="team.length > 0 ? item.avator : ''" alt="" v-for="(item, index) in team" :key="index"
                      v-show="index != 0" />
                    <img :src="team.length > 0 ? team[0].avator : ''" alt="" :style="{ 'margin-left': x + 'px' }" />
                    <img :src="team.length > 0 ? item.avator : ''" alt="" v-for="(item, index) in team"
                      :key="'info'+index" v-show="index != 0" />
                  </div>
                  <div class="col-md-8 aboutUs" @mouseenter="enterAbout" @mouseleave="leaveAbout">
                    <!-- <div class="checkLeft" @click="checkLeft" v-show="showCheck">
                      <img src="../static/images/left.png" alt="" />
                    </div>
                    <div class="checkRight" @click="checkRight" v-show="showCheck">
                      <img src="../static/images/right.png" alt="" />
                    </div> -->

                  </div>
                </div>
              </div>
              <div class="intro" style="margin-top: 0;">
                <div class="intro_about">
                  {{ about }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <footer ref="contact" class="footer">
        <div class="footer_left">
          <div class="footer_left_logo">
            <img src="../static/images/logo.png" alt="" style="margin-top:67px">
          </div>
          <div class="footer_left_add">
            <p class="contacts" style="padding-bottom: 15px;">
              <i class="fa fa-map-marker"></i> 地址：&nbsp;&nbsp;&nbsp;&nbsp;成都市高新区天府二街复城国际T1-18层
            </p>
            <p class="contacts" style="padding-bottom: 15px;">
              <i class="fa fa-phone"></i> 电话：&nbsp;&nbsp;&nbsp;&nbsp;028-60102873
            </p>
            <p class="contacts" style="padding-bottom: 15px;">
              <i class="fa fa-desktop"></i> 网址：&nbsp;&nbsp;&nbsp;&nbsp;https://www.schaxk.com
            </p>
            <p class="contacts">
              <i class="fa fa-yelp"></i>传真： &nbsp;&nbsp;&nbsp;&nbsp;028-60102875
            </p>
          </div>
        </div>
        <div class="footer_right">
          <p class="copyright">
            版权公告 2019-2022 四川慧安信科科技有限公司版权所有
          </p>
          <p class="copyright">
            <a style="color:#53f3f3" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20011909号-1</a>   <a style="color:#53f3f3" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20011909号-2</a>
          </p>
          <p class="copyright">
            川公网安备 51019002002737号
          </p>
        </div>
      </footer>

    </div>

    <!-- loading动画 -->
    <div class="loading" :style="{ height: clientHeight + 'px', 'min-height': '800px' }" v-if="loading">
      <!-- <img src="../static/images/01.gif" alt="" /> -->
      <div class="spinner">
        <div class="spinner-container container1">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
        </div>
        <div class="spinner-container container2">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
        </div>
        <div class="spinner-container container3">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import request from "../utils/request";
  import baseurl from "../utils/config";
  import animate from "../utils/animate";
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        // isItemTitle: true,
        isItem: true,
        isIndustry: false,
        isActive: true,
        datashow: "",
        height: "",
        dataflow: "",
        showCheck: false, //关于我们图片切换按钮
        successCaseAni: "", //成功案例动画
        newsAni: "", //新闻资讯动画
        productAni: "", //产品展示动画
        aboutAni: "", //关于我们动画
        loading: true, //加载完成之前显示动画
        productIndex: 0, //产品acitve
        headerType: "home", //顶部导航栏类别，切换active状态
        isScroll: false, //顶部导航栏滑动显示背景
        clientHeight: "", //界面高度，定义容器高度
        bannerSrc: [], //轮播图数据
        caseList: [], //成功案例数据
        initCaseList: [], //全部案例
        index: 0, //轮播图相关
        intervalId: "", //轮播图循环定时器
        // timer: "",
        bannerAni: "", //轮播图动画
        titleAni: "", //轮播图标题
        title: [
          "四川慧安信科科技有限公司",
        ], //标题
        description: [
          "城市治理因我们而智慧",
          "政务服务因我们而贴心",
        ],
        keywords: [
          "互联网、智慧城市、智慧城管、物联网、系统集成、城市管理",
        ],
        totalNews: [], //总新闻数据，模拟分页
        nowNews: [],
        companyNews: [],
        businessNews: [],
        product:[],
        allProduct: [],
        allPics : [],
        pics : [],
        classify: [], //产品分类
        totalNumber: 0, //新闻总数
        totalPage: 0, //总页数
        nowPage: 1,
        pageSize: 3, //每页3条新闻
        newsType: 0,
        team: [], //关于我们
        about: "",
        x: 0,
        intervalIdAbout: "", //关于我们图片动画
        scrollTop: 0,
        nowIntroduceIndex: 0,

        have: {
          haveProduct: false,
          haveNews: false,
          haveTeam: false,
          haveSuccesssCase: false
        }

      };
    },
    metaInfo(){
      return {
        title:this.title,
        meta: [
          { vmid: 'description', name: 'description', content:this.description },
          { vmid: 'keywords', name: 'keywords', content:this.keywords}
        ]
      }
    },
    //挂载前获得屏幕高度
    beforeMount() {
      this.clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
    },
    //挂载时监听scroll事件
    mounted() {
      this.getData();
      window.addEventListener("scroll", this.handleScroll);
      // const banner = this.$refs['banner'];
      // 通过ref属性获取到 ul和ol属性
      // const ul = that.$refs['ul'];
      // const ol = this.$refs['circle'];
      // const arrowr = this.$refs['arrowr'];
      // const arrowl = this.$refs['arrowl'];
      //   for (var i = 0; i < ul.children.length; i++) {
      //   var li = document.createElement('li');
      //   li.setAttribute('index', i);
      //   ol.appendChild(li);
      //   ol.children[0].className = 'current';
      //   li.addEventListener('click', function () {
      //     for (var i = 0; i < ol.children.length; i++) {
      //       ol.children[i].className = '';
      //     }
      //     this.className = 'current';
      //     var index = this.getAttribute('index');
      //     num = index;
      //     circle = index;
      //     var bannerWidth = banner.offsetWidth;
      //     animate(ul, -index * bannerWidth);
      //   })
      // }

      // var first = ul.children[0].cloneNode(true);
      // ul.appendChild(first);
      // var num = 0;
      // var circle = 0;
      // // var flag = true;
      // arrowr.addEventListener('click', function () {
      //   // flag = false;
      //   if (num == ul.children.length - 1) {
      //     ul.style.left = 0;
      //     num = 0;
      //   }
      //   num++;
      //   var bannerWidth = banner.offsetWidth;
      //   animate(ul, -num * bannerWidth);
      //   circle++;
      //   if (circle == ol.children.length) {
      //     circle = 0;
      //   }
      //   for (var i = 0; i < ol.children.length; i++) {
      //     ol.children[i].className = '';
      //   }
      //   ol.children[circle].className = 'current';
      // });
      // arrowl.addEventListener('click', function () {
      //   var bannerWidth = banner.offsetWidth;
      //   if (num == 0) {
      //     num = ul.children.length - 1;
      //     //console.log(num);
      //     //console.log(bannerWidth)
      //     ul.style.left = -num * bannerWidth + 'px';
      //     //console.log(ul.style.left)
      //   }
      //   num--;
      //   animate(ul, -num * bannerWidth);
      //   circle--;
      //   if (circle < 0) {
      //     circle = ol.children.length - 1;
      //   }
      //   for (var i = 0; i < ol.children.length; i++) {
      //     ol.children[i].className = '';
      //   }
      //   ol.children[circle].className = 'current';
      // });
      // if(ul.children.length>2){
      //   var timer = setInterval(function () { // eslint-disable-line no-unused-vars
      //   arrowr.click();
      // }, 5000);
      // }
    },
    computed: mapState({
      sign(state) {
        return state.sign;
      },
    }),
    watch: {
      sign(val) {
        window.addEventListener("scroll", this.handleScroll);
        this.goAnchor(val, 50);
      },
    },
    created() {
      this.loading = true;
      this.interAbout();
      if (!this.have.haveProduct) {
        this.getProduct();
        this.getType();
      }
    },
    destroyed() {
      // 离开该页面需要移除这个监听的事件，不然会报错
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      toggleActive() {
        this.isIndustry = !this.isIndustry;
        this.isActive = !this.isActive;
      },
      isActiveNews() {
        this.isActive = true;
        this.isIndustry = !this.isIndustry;
      },
      async getTeam() {
        this.have.haveTeam = true;
        const data = await request("/official/us/getUsDisplay");
        // ("data", data);
        this.about = data.data.data.officialUs.intro;
        this.team = data.data.data.officialUsPicList.map((item) => {
          return {
            avator: baseurl + item.pic,
          };
        });
      },
      handleScroll() {
        try {
          this.scrollTop =
            (window.pageYOffset ||
              document.documentElement.scrollTop ||
              document.body.scrollTop) + this.$refs["header"].offsetHeight;
          if (this.scrollTop > 200) {
            this.isScroll = true;
          } else {
            this.isScroll = false;
          }
          let contact = this.$refs["contact"].offsetTop; //模块相对于内容顶部的距离
          //console.log("con"+contact);
          //console.log("scro"+this.scrollTop);
          if (this.scrollTop < 110) {
            this.headerType = "home";
          } else if (this.scrollTop > 110 && this.scrollTop < 750) {
            this.headerType = "product";
          } else if (this.scrollTop > 750 && this.scrollTop < 1600) {
            //  this.have.haveSuccesssCase ? '' : this.getSuccessCase()
             if(!this.have.haveSuccesssCase){
               this.getSuccessCase()
             }
            this.headerType = "successCase";
            this.successCaseAni = "animated fadeInRight";

          } else if (this.scrollTop > 1600 && this.scrollTop < 2600) {
            if (!this.have.haveNews) {
              this.getCompanyNews()
              this.getBusinessNews()
            }
            this.headerType = "news";
            this.newsAni = "animated fadeInLeft";
          } else if (this.scrollTop > 2600) {
            if (!this.have.haveTeam) {
              this.getTeam()
            }

            this.aboutAni = "animated fadeInUp";

            this.headerType = "about";

          } else if (this.scrollTop > contact - 50) {

            this.headerType = "contact";
          }
        } catch (e) {
          return;
        }
      },
      imgReady(pics) {
        // console.log(pics.imgUrl);
        const picsAll = pics.map((imgUrl) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imgUrl;
            img.onload = () => resolve(imgUrl);
            img.onerror = () => reject(new Error(imgUrl + "error"));
          });
        });
        Promise.all(picsAll)
          .then(() => {
            this.bannerSrc = pics;
            this.bannerAni = "animated big";
            this.titleAni = "animated bounceOutDown";
            this.inter();
          })
          .catch((e) => {
            (e);
            this.loading = false;
          });
      },
      imgBanner(){
        setTimeout(() =>{
          const ul = this.$refs['ul'];
          // console.log(ul);
          const ol = this.$refs['circle'];
          const banner = this.$refs['banner'];
          const arrowr = this.$refs['arrowr'];
          const arrowl = this.$refs['arrowl'];
          for (var i = 0; i < ul.children.length; i++) {
          var li = document.createElement('li');
          li.setAttribute('index', i);
          ol.appendChild(li);
          ol.children[0].className = 'current';
          li.addEventListener('click', function () {
            for (var i = 0; i < ol.children.length; i++) {
              ol.children[i].className = '';
            }
            this.className = 'current';
            var index = this.getAttribute('index');
            num = index;
            circle = index;
            var bannerWidth = banner.offsetWidth;
            animate(ul, -index * bannerWidth);
            })
          }
          var first = ul.children[0].cloneNode(true);
          ul.appendChild(first);
          var num = 0;
          var circle = 0;
          // var flag = true;
          arrowr.addEventListener('click', function () {
            // flag = false;
            if (num == ul.children.length - 1) {
              ul.style.left = 0;
              num = 0;
            }
            num++;
            var bannerWidth = banner.offsetWidth;
            animate(ul, -num * bannerWidth);
            circle++;
            if (circle == ol.children.length) {
              circle = 0;
            }
            for (var i = 0; i < ol.children.length; i++) {
              ol.children[i].className = '';
            }
            ol.children[circle].className = 'current';
          });
          arrowl.addEventListener('click', function () {
            var bannerWidth = banner.offsetWidth;
            if (num == 0) {
              num = ul.children.length - 1;
              //console.log(num);
              //console.log(bannerWidth)
              ul.style.left = -num * bannerWidth + 'px';
              //console.log(ul.style.left)
            }
            num--;
            animate(ul, -num * bannerWidth);
            circle--;
            if (circle < 0) {
              circle = ol.children.length - 1;
            }
            for (var i = 0; i < ol.children.length; i++) {
              ol.children[i].className = '';
            }
            ol.children[circle].className = 'current';
          });
          if(ul.children.length>2){
            var timer = setInterval(function () { // eslint-disable-line no-unused-vars
            arrowr.click();
          }, 5000);
          }
              });
      },
      async getData() {
        try {
          const bannerData = await request("/official/slideshow/listDisplay");
          const imgs = bannerData.data.rows.map((item) => {
            return baseurl + item.imgUrl;
          });
          this.loading = false;
          this.imgReady(imgs);
          //console.log(imgs)
          this.allPics = imgs;
          this.imgBanner();
          // setTimeout(() =>{
          // const ul = this.$refs['ul'];
          // // console.log(ul);
          // const ol = this.$refs['circle'];
          // const banner = this.$refs['banner'];
          // const arrowr = this.$refs['arrowr'];
          // const arrowl = this.$refs['arrowl'];
          // for (var i = 0; i < ul.children.length; i++) {
          // var li = document.createElement('li');
          // li.setAttribute('index', i);
          // ol.appendChild(li);
          // ol.children[0].className = 'current';
          // li.addEventListener('click', function () {
          //   for (var i = 0; i < ol.children.length; i++) {
          //     ol.children[i].className = '';
          //   }
          //   this.className = 'current';
          //   var index = this.getAttribute('index');
          //   num = index;
          //   circle = index;
          //   var bannerWidth = banner.offsetWidth;
          //   animate(ul, -index * bannerWidth);
          //   })
          // }
          // var first = ul.children[0].cloneNode(true);
          // ul.appendChild(first);
          // var num = 0;
          // var circle = 0;
          // // var flag = true;
          // arrowr.addEventListener('click', function () {
          //   // flag = false;
          //   if (num == ul.children.length - 1) {
          //     ul.style.left = 0;
          //     num = 0;
          //   }
          //   num++;
          //   var bannerWidth = banner.offsetWidth;
          //   animate(ul, -num * bannerWidth);
          //   circle++;
          //   if (circle == ol.children.length) {
          //     circle = 0;
          //   }
          //   for (var i = 0; i < ol.children.length; i++) {
          //     ol.children[i].className = '';
          //   }
          //   ol.children[circle].className = 'current';
          // });
          // arrowl.addEventListener('click', function () {
          //   var bannerWidth = banner.offsetWidth;
          //   if (num == 0) {
          //     num = ul.children.length - 1;
          //     //console.log(num);
          //     //console.log(bannerWidth)
          //     ul.style.left = -num * bannerWidth + 'px';
          //     //console.log(ul.style.left)
          //   }
          //   num--;
          //   animate(ul, -num * bannerWidth);
          //   circle--;
          //   if (circle < 0) {
          //     circle = ol.children.length - 1;
          //   }
          //   for (var i = 0; i < ol.children.length; i++) {
          //     ol.children[i].className = '';
          //   }
          //   ol.children[circle].className = 'current';
          // });
          // if(ul.children.length>2){
          //   var timer = setInterval(function () { // eslint-disable-line no-unused-vars
          //   arrowr.click();
          // }, 5000);
          // }
          //     });
            } catch (err) {
              this.loading = false;
              this.$message({
                showClose: true,
                message: "系统错误，请稍后重试",
                type: "error",
              });
              ("err", err);
            }
      },
      async getSuccessCase() {
        this.have.haveSuccesssCase = true;
        const data = await request("/official/success/listDisplay");
        this.caseList = data.data.rows.map((item) => {
          const obj = {
            icon: baseurl + item.mainPic,
            title: item.title,
            introduce: item.intro,
            content: item.content,
          };
          return obj;
        });
      },
      openDetail(info, sign) {
        window.removeEventListener("scroll", this.handleScroll);
        if (info.content && info.content.indexOf("mp.weixin.qq.com") > -1) {
          window.open(info.content);
          return;
        }
        this.$router.push({
          name: "Detail",
          params: {
            info,
            sign
          }
        });
      },
      openAllItem(index) {
        if (index == 7) {
          this.$router.push({
            name: "CaseItem",
          });
        }
      },
      enterBanner() {
        clearInterval(this.intervalId, this.timer);
        this.datashow = "block";
        this.bannerAni = "animated small";
        this.titleAni = "animated bounceInDown";
        // clearInterval(timer);
        this.timer = null;
      },
      leaveBanner() {
        clearInterval(this.intervalId);
        this.datashow = "none";
        this.bannerAni = "animated big";
        this.titleAni = "animated bounceOutDown";
        this.inter();
      },
      inter() {
        this.intervalId = setInterval(() => {
          this.index++;
          this.bannerAni = "";
          this.titleAni = "";

          this.$nextTick(() => {
            this.bannerAni = "animated big";
            this.titleAni = "animated bounceOutDown";
          });
        }, 4000);
      },
      goAnchor(el, speed) {
        this.headerType = el;
        //   let windowH = window.innerHeight; //浏览器窗口高度
        //   let h = this.$refs[el].offsetHeight; //模块内容高度
        let t = this.$refs[el].offsetTop; //模块相对于内容顶部的距离
        // ("tttttttttttttt", t);
        let top = t - 80; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //滚动条距离顶部高度
        // ("scroll", scrollTop);
        let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step() {
          //判断让滚动条向上滚还是向下滚
          if (scrollTop < top) {
            if (currentTop <= top) {
              //   window.scrollTo(x,y) y为上下滚动位置
              window.scrollTo(0, currentTop);
              requestId = window.requestAnimationFrame(step);
            } else {
              window.cancelAnimationFrame(requestId);
            }
            //向下滚动
            currentTop += speed;
          } else {
            if (top <= currentTop) {
              //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
              window.scrollTo(0, currentTop - speed);
              requestId = window.requestAnimationFrame(step);
            } else {
              window.cancelAnimationFrame(requestId);
            }
            //向上滚动
            currentTop -= speed;
          }
        }
        window.requestAnimationFrame(step);
      },
      async getCompanyNews() {
        this.have.haveNews = true;
        const data = await request("/official/news/listDisplay");
        this.companyNews = data.data.rows.map((item) => {
          const obj = {
            title: item.title,
            introduce: item.intro,
            content: item.content,
            format: item.format,
            imgSrc: baseurl + item.mainPic,
            author: item.author,
            date: item.createTime.substr(0, 11),
          };
          return obj;
        });
        this.totalNumber = this.companyNews.length;
        this.totalNumber % this.pageSize != 0 ?
          (this.totalPage = parseInt(this.totalNumber / this.pageSize) + 1) :
          (this.totalPage = parseInt(this.totalNumber / this.pageSize));
        this.nowNews = this.companyNews.slice(
          this.nowPage - 1,
          this.pageSize * this.nowPage
        );
      },
      async getBusinessNews() {
        const data = await request("/official/dynamics/listDisplay");
        this.businessNews = data.data.rows.map((item) => {
          const obj = {
            title: item.title,
            introduce: item.intro,
            content: item.content,
            imgSrc: baseurl + item.mainPic,
            format: item.format,
            author: item.author,
            date: item.createTime.substr(0, 11),
          };
          return obj;
        });
      },
      async getProduct() {

        const data = await request("/official/product/listDisplay");
        this.allProduct = data.data.rows.map((item) => {
          const obj = {
            sign: item.productId,
            title: item.title,
            introduce: item.intro,
            content: item.content,
            imgSrc: baseurl + item.mainPic,
          };
          return obj;
        });
        this.product = this.allProduct;
      },
      filterProduct(item, index) {
        if (item.filter == "all") {
          this.product = this.allProduct;
          this.productIndex = index;
          // this.class(.on):addClass("on");
        } else {
          this.productIndex = index;
          let temp = JSON.parse(JSON.stringify(this.allProduct));
          this.product = [];
          temp.map((c) => {
            if (c.sign == item.filter) {
              this.product.push(c);
            }
          });
        }
      },
      async getType() {
        this.have.haveProduct = true;
        const data = await request("/official/productCategory/listDisplay");
        this.classify = data.data.rows.map((item) => {
          const obj = {
            name: item.categoryName,
            filter: item.id,
          };
          return obj;
        });
        this.classify.unshift({
          name: "全部",
          filter: "all",
        });
      },
      enter(type) {
        this.nowPage = 1;
        if (type == 0) {
          this.nowNews = this.companyNews.slice(
            this.nowPage - 1,
            this.pageSize * this.nowPage
          );
          this.newsType = 0;
          this.totalNumber = this.companyNews.length;
        } else {
          this.nowNews = this.businessNews.slice(
            this.nowPage - 1,
            this.pageSize * this.nowPage
          );
          this.newsType = 1;
          this.totalNumber = this.businessNews.length;
        }
        this.totalNumber % this.pageSize != 0 ?
          (this.totalPage = parseInt(this.totalNumber / this.pageSize) + 1) :
          (this.totalPage = parseInt(this.totalNumber / this.pageSize));
      },
      nextPage() {
        this.nowPage++;
        if (this.newsType == 0) {
          this.nowNews = this.companyNews.slice(
            (this.nowPage - 1) * this.pageSize,
            this.pageSize * this.nowPage
          );
        } else {
          this.nowNews = this.businessNews.slice(
            (this.nowPage - 1) * this.pageSize,
            this.pageSize * this.nowPage
          );
        }
      },
      prevPage() {
        this.nowPage--;
        if (this.newsType == 0) {
          this.nowNews = this.companyNews.slice(
            (this.nowPage - 1) * this.pageSize,
            this.pageSize * this.nowPage
          );
        } else {
          this.nowNews = this.businessNews.slice(
            (this.nowPage - 1) * this.pageSize,
            this.pageSize * this.nowPage
          );
        }
      },
      interAbout() {
        if (/Mobile|Android|webOS|iPhone|iPad|Phone/i.test(navigator.userAgent)) {
          this.intervalIdAbout = setInterval(() => {
            if (Math.abs(this.x) > 220 * (this.team.length - 1)) {
              clearInterval(this.intervalIdAbout);
            } else {
              this.x = this.x - 1;
            }
          }, 20);
        } else {
          this.intervalIdAbout = setInterval(() => {
            if (Math.abs(this.x) > 220 * (this.team.length - 5)) {
              clearInterval(this.intervalIdAbout);
            } else {
              this.x = this.x - 1;
            }
          }, 20);
        }
      },

      enterAbout() {
        this.showCheck = true;
        clearInterval(this.intervalIdAbout);
      },
      leaveAbout() {
        clearInterval(this.intervalIdAbout);
        setTimeout(() => {
          this.interAbout();
        }, 2000);

        this.showCheck = false;
      },
      enterAllProduct(){
        document.querySelector('body').setAttribute('style','overflow:hidden;');
      },
      leaveAllProduct(){
        document.querySelector('body').setAttribute('style','overflow:auto;');
      },
      enterProduct(index) {
        if (/Mobile|Android|webOS|iPhone|iPad|Phone/i.test(navigator.userAgent)) {
          return;
        }
        this.nowIntroduceIndex = index;
      },
      leaveProduct(index) {
        this.nowIntroduceIndex = 0;
        if (index !== 0) {
          this.itemTitle = '';
        }
      },
    },
  };
</script>
<style lang="scss">
  * {
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar{
    width: 0 !important;
    background-color: transparent;
    display:none;
  }

  html{
    scrollbar-width: none;
  }

  .container {
    overflow-x: hidden;
    max-width: 1920px;
    margin: 0 auto;
    // position: relative;
    // top: 0;
    // left: 0;
    // transform: translate(-0,-0);
  }

  // banner部分
  .header-container {
    overflow: hidden;
    width: 1920px;
    // width: 100%;
    height: 618px;

    // background-color: pink;
    .banner {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      a {
        text-decoration: none;
      }

      li {
        list-style: none;
      }

      .arrowl,
      .arrowr {
        display: none;
        position: absolute;
        top: 50%;
        margin-top: -20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: rgba(0, 0, 0, .3);
        text-align: center;
        color: #fff;
        border-radius: 50%;
        font-family: '宋体';
        z-index: 2;
      }

      .arrowr {
        right: 0;
      }
      ul {
        position: absolute;
        top: 0;
        left: 0;
        width: 800%;
      }
      ul li {
        float: left;
      }



      .circle {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }

      .circle li {
        float: left;
        width: 15px;
        height: 15px;
        /*background-color: #fff;*/
        border: 1px solid rgba(113, 141, 202, 0.5);
        margin: 0 3px;
        border-radius: 50%;
        /*鼠标经过显示小手*/
        cursor: pointer;
      }

      .current {
        background-color: #fff;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .banner {
    position: relative;

    img {
      width: 100%;
    }

    .title {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      font-weight: 600;
    }

    .header {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      height: 4%;
      display: flex;
      align-items: center;
      background-color: transparent;
      height: 80px;
      z-index: 10;

      .header-top {
        position: relative;
        width: 1920px;
        height: 100%;
        margin: 0 auto;
      }

      .header-right {
        // margin-left: 455px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 400;

        .div:hover {
          border-bottom: 1px solid #4799c9;
        }

        >div {
          background-color: transparent;
          color: #fff;
          padding: 10px 20px;
        }

        >div:hover {
          cursor: pointer;
          color: #fff;
          font-weight: 900;
          text-shadow: 0 0 15px rgb(71, 153, 201);
          // border-bottom: 1px solid #4799c9;
        }
      }

      .logo {
        margin-left: 392px;
        display: flex;
        justify-content: center;
        width: 129px;
        height: 43px;
        // img {
        //   width: 126px;
        //   height: 26px;
        // }
      }
    }
  }

  .ani {
    animation: pulse;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 4s;
    /* don't forget to set a duration! */
  }

  .successsCase-container {
    position: relative;
    margin-top: 5%;
    width: 1920px;
    text-align: center;
    position: relative;


    .success_rectangle {
      width: 70px;
      height: 26px;
      background-color: #3B64BA;
      opacity: 0.7;
      position: absolute;
      left: 964px;
      top: 21px;
    }

    .content {
      background-color: #efefef;
      width: 100%;
      height: 850px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .success_box{
        width: 1211px;
        height: 730px;
        overflow: hidden;
        // margin: 20px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      .item {
        position: relative;
        cursor: pointer;
        width: 284px;
        height: 347px;
        display: flex;
        margin-right: 18px;
        margin: 10px 5px;
      }

      .item:hover {
        display: block;
        border-radius: 6px;
        cursor: pointer;
        transform: scale(1.01);
        transition: all 0.5s ease;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
      }

      .item:hover .item-mask {
        height: 100%;
        transition: 500ms;
      }

      .item:hover .item-right {
        position: absolute;
        top: 40%;
      }

      .item_more {
        width: 100%;
        text-align: center;
        /*margin-top: 182px;*/
        line-height: 347px;
        color: #333333;
        font-size: 18px;
        font-family: 'Alibaba-PuHuiTi-M';
      }

      .item_more:hover {
        cursor: pointer;
        // box-shadow: none;
      }

      .item img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        cursor: pointer;
      }

      .item-mask {
        position: absolute;
        bottom: 0px;
        //left: -1px;
        width: 100%;
        height: 87px;
        border-radius: 5px;
        cursor: pointer;
        background: rgba(0, 0, 0, .4);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: height 300ms;
        box-sizing: border-box;
      }

      .item-right {
        position: absolute;
        bottom: 0px;
        // left: -1px;
        width: 100%;
        text-align: center;
        padding-top: 10px;

        >div:hover {
          cursor: pointer;
        }

        >div:nth-child(1) {
          color: #fff;
          font-size: 20px;
          font-family: 'Alibaba-PuHuiTi-M';
        }

        >div:nth-child(2) {
          margin-bottom: 15px;
          margin-top: 15px;
          color: #fff;
          font-size: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          -webkit-transition: top .2s ease-in;
          -moz-transition: top .2s ease-in;
          transition: top .2s ease-in;
        }
      }
    }
  }

  // 新闻资讯样式
  .news-container {
    position: relative;
    width: 1920px;
    margin-top: 5%;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: center;
    }

    .success_rectangle {
      width: 70px;
      height: 26px;
      background-color: #3B64BA;
      opacity: 0.7;
      position: absolute;
      left: 964px;
      top: 22px;
    }

    .news_content {
      width: 1920px;
      height: 790px;
      background-color: #F6F9FF;
      margin-top: 60px;
      // margin-left: 10%;
    }
  }



  .news .news_industry {
    background: url("../static/images/new.png") no-repeat;
    color: #fff;
    width: 134px;
    height: 33px;
    line-height: 33px;
    padding-left: 0px;
  }

  .news {
    margin-top: 50px;
    margin-bottom: 115px;
    // margin-left: 806px;
    display: flex;
    justify-content: center;

    .newsBox{
      width: 268px;
      height: 33px;
      line-height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .newsactive {
      background: url("../static/images/new.png") no-repeat;
      color: #fff;
      width: 134px;
      height: 33px;
      line-height: 33px;
      padding-left: 0px;
      display: block;
    }

    div {
      width: 134px;
      height: 33px;
      line-height: 33px;
      float: left;
      font-size: 16px;
      margin: 0 auto;
      text-align: center;
    }
  }

  .news>div>img {
    width: 50px;
    height: 50px;
  }

  .companyItem {
    display: flex;
    margin-top: 40px;
    width: 1211px;
    height: 600px;
    margin: 0 auto;
  }

  .firstNews {
    width: 455px;
    height: 118px;

    h4 {
      width: 446px;
      height: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-height: 38px;
      font-weight: 700;
      font-size: 20px;
      margin-top: 15px;
    }

    div {
      font-size: 15px;
    }

    >div:nth-child(2) {
      // margin: 5px 0;
      font-size: 17px;
      margin-top: 5px;
      color: #4D4C4C;
      width: 450px;
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    h4:hover {
      cursor: pointer;
      color: #6982e2;
    }
  }

  .companyItem>div {
    width: 528px;
    height: 317px;
    margin-right: 50px;
    margin-top: 50px;
  }

  .companyItem>div>img {
    width: 100%;
    height: 100%;
  }

  .companyItem>div>div>div>div {
    float: left;
    color: #666666;
    margin-right: 47px;
    font-size: 14px;
  }

  .companyItem>div>div>h3 {
    line-height: 30px;
  }

  .companyItem>div>div>h3:hover {
    color: #6982e2;
    cursor: pointer;
  }

  .news-otherItem {
    width: 590px;
    height: 130px;
    margin-bottom: 30px;
    display: flex;
  }

  .news-otherItem .num {
    float: left;
    width: 72px;
    height: 118px;
    font-size: 44px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #3B64BA;
    line-height: 118px;
    opacity: 0.4;
  }

  .news-otherItem .news_title {
    width: 455px;
    font-size: 20px;
    height: 30px;
    font-weight: 700;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .news-otherItem .news_introduce {
    width: 455px;
    height: 50px;
    margin: -16px 0px 15px 0px;
    font-size: 17px;
    color: #4D4C4C;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .news-otherItem .news_title:hover {
    color: #6982e2;
    cursor: pointer;
  }

  .page {
    /*margin-top: 63px;*/
    /*margin-left: 500px;*/
    width: 550px;
    height: 22px;
    line-height: 22px;
    color: #696c6e;
    margin-right: 30px;
    text-align: right;
  }

  .page>span:nth-child(1) {
    // position: absolute;
    // top: 700px;
    // left: 1400px;
  }

  .page>span:nth-child(2) {
    // position: absolute;
    // left: 1400px;
  }

  .page>span:hover {
    cursor: pointer;
    color: black;
  }

  .news>div:hover {
    cursor: pointer;
  }

  // .newsHover {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }

  // 产品展示样式
  .product-container {
    position: relative;
    width: 1920px;
    margin-top: 5%;
    // margin-top: 52px;
    height: 820px;

    .title {
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }

    .success_rectangle {
      width: 70px;
      height: 26px;
      background-color: #3B64BA;
      opacity: 0.7;
      position: absolute;
      left: 964px;
      margin-top: 22px;
    }

    .subTitle {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .sort {
      position: relative;
      margin-top: 40px;
      display: flex;
      justify-content: center;

      >div {
        // border: solid 1px #9a9999;
        padding: 10px 50px;
        margin-left: 10px;
        font-size: 17px;
        font-weight: 300;
      }

      >div:hover {
        cursor: pointer;
        color: #5db7eb;

        p {
          margin-left: 27px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-bottom-color: #5db7eb;
        }
      }

      .check {
        cursor: pointer;
        color: #5db7eb;
      }
    }

    .product_show {
      position: relative;
      width: 1211px;
      height: 476px;
      background-color: #fff;
      margin: 0 auto;
      margin-top: 76px;
      border: 3px solid #dfe5f1;

      .product_show_left{
        // width: 278px;
        width: 310px;
        min-height:476px;
        max-height:476px;
        overflow-x: hidden;
        overflow-y: auto;
      }

      ::-webkit-scrollbar {
      /*隐藏滚轮*/
      display: none;
      }

      .item_title {
        width: 278px;
        height: 68px;
        text-indent: 2em;
        // font-weight: 700;
        color: #000308;
        font-size: 20px;
        line-height: 68px;
      }

      .item_title:hover {
        cursor: pointer;
        color: #4c6db2;
        width: 397px;
        background: url(../static/images/bg.png);
      }

      .itemTitle {
        // display: none;
        font-size: 20px;
        line-height: 68px;
        width: 291px;
        height: 68px;
        text-indent: 2em;
        cursor: pointer;
        color: #4c6db2;
        width: 397px;
        background: url(../static/images/bg.png);
      }

      // .itemTitle:hover {
      //   background: none;
      // }

      .check {
        color: #6886c1;
        cursor: pointer;
      }

      img {
        position: absolute;
        top: -14px;
        left: 290px;
        width: 639px;
        height: 509px;
      }

      .product_info {
        position: absolute;
        top: 0;
        right: 0;
        width: 283px;
        height: 478px;

        .product_info_title {
          margin-top: 48px;
          // margin-left: 57px;
          color: #333333;
          font-size: 20px;
          text-align: center;
          // color: #333333;
          font-weight: 700;
        }

        .product_info_introduce {
          text-indent: 2em;
          margin-top: 30px;
          margin-left: 20px;
          margin-right: 30px;
          line-height: 30px;
          color: #858585;
          font-size: 16px;
          text-align: justify;
        }
      }


    }

    .sortActive {
      color: #3A5EAA;
      background: url("../static/images/triangle.png") no-repeat;
      background-position: 50% 103%;
    }

    .produceIntroduce {
      >div:nth-child(1) {
        text-align: center;
        margin-bottom: 5px;
        font-weight: 600;
      }

      >div:nth-child(2) {
        text-align: justify;
        text-indent: 2rem;
        font-size: 15px;
        padding: 5px 10px;
      }
    }

    .item {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      width: 70%;
      margin-left: 15%;

      >div {
        width: 23%;
        margin-left: 2%;
        margin-top: 2%;

        .productTitle {
          text-align: center;
        }
      }

      >div:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition: all 0.5s ease;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
      }
    }
  }

  // 关于我们样式
  .about-container {
    position: relative;
    margin-top: 5%;
    width: 1920px;

    .success_rectangle {
      width: 70px;
      height: 26px;
      background-color: #3B64BA;
      opacity: 0.7;
      position: absolute;
      left: 964px;
      top: 20px;
    }

    .separator {
      width: 100%;
      height: 646px;
      background-image: url(../static/images/aboutUs.png);

      .about_bottom {
        // margin-top: 53px;
      }
    }

    .title {
      text-align: center;
      padding-bottom: 50px;
    }

    .abouts {
      width: 1150px;
      height: 500px;
      margin: 0 auto;
    }

    .row {
      position: relative;
      overflow: hidden;
      width: 1150px;
      height: 260px;
      transform: translate3d(0, 0, 0);

    }

    .row:hover {
      animation-play-state: paused;
    }

    .aboutImg {
      display: flex;
      // margin-top: 50px;
      // width: 400%;
      position: absolute;
      left: 355px;
      top: 50px;
      // height: 100%;
      // transition: opacity 0.5s ease-out;
      // animation: moveing 5s linear infinite;
      animation: slideshow 50s linear infinite;
      // transform: translate3d(0, 0, 0);

      img {
        width: 356px;
        height: 260px;
      }
    }

    @keyframes slideshow {
      0% {
        left: 0;
      }

      100% {
        left: -200%;
      }
    }

    // @keyframes moveing {
    //   from {
    //     transform: translateX(20);
    //   }

    //   to {
    //     transform: translateX(-750px);
    //   }
    // }
  }

  .intro {
    height: 256px;
    margin-top: 3%;
    margin-bottom: 15px;
    text-align: left;
    text-indent: 2em;
    font-size: 18px;
    line-height: 30px;
    color: #333333;
    background-color: #fff;

    .intro_about {
      padding: 34px 22px 0px 22px;
      line-height: 35px;
      text-align: justify;
    }
  }

  .aboutUs {
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }

  .aboutUs:hover {
    cursor: pointer;
  }

  .aboutUs>img {
    width: 370px;
    height: 265px;
    margin-top: 49px;
    // padding: 10px;
    // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    // margin-right: 10px;
  }

  // 底部样式
  .footer {
    width: 1920px;
    height: 302px;
    background-image: url("../static/images/footer.png");

    .footer_left {
      float: left;
      margin-left: 578px;
    }

    .footer_left_add {
      width: 333px;
      margin-top: 35px;
      color: #fff;
      font-size: 14px;
      border-right: 1px solid #fff;
    }

    .footer_right {
      float: right;
      margin-top: 150px;
      margin-right: 581px;
      color: #fff;
      font-size: 14px;

      .copyright {
        margin-bottom: 20px;
      }
    }
  }

  .section {
    padding: 20px;
  }

  .active {
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0 15px rgb(71, 153, 201);
  }

  .scroll {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: rgba(93, 183, 235) !important;
    height: 80px;
    z-index: 10;
  }

  .loading {
    width: 100%;
    background-color: #faf7ef;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
  }

  .spinner {
    width: 40px;
    height: 40px;
    position: relative;
  }

  .container1>div,
  .container2>div,
  .container3>div {
    width: 6px;
    height: 6px;
    background-color: #67cf22;

    border-radius: 100%;
    position: absolute;
    -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
    animation: bouncedelay 1.2s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .spinner .spinner-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .container2 {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }

  .container3 {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
  }

  .circle1 {
    top: 0;
    left: 0;
  }

  .circle2 {
    top: 0;
    right: 0;
  }

  .circle3 {
    right: 0;
    bottom: 0;
  }

  .circle4 {
    left: 0;
    bottom: 0;
  }

  .container2 .circle1 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .container3 .circle1 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .container1 .circle2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .container2 .circle2 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  .container3 .circle2 {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  .container1 .circle3 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  .container2 .circle3 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  .container3 .circle3 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  .container1 .circle4 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  .container2 .circle4 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }

  .container3 .circle4 {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes bouncedelay {

    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes bouncedelay {

    0%,
    80%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    40% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  @media (max-width: 400px) {
    // .successsCase-container .content .item-right>div:nth-child(2) {
    //   width: 100%;
    // }

    .header-container {
      width: 100%;

      .banner {
        height: 300px;

        .title {
          font-size: 20px;
        }

        img {
          width: 100%;
        }
      }

      .header {
        .logo {
          width: 70%;
          margin-left: 45%;

          img {
            width: 126px;
            height: 26px;
          }
        }

        .header-right {
          font-size: 10px;
          margin-left: -30px;
          width: 90%;

          >div {
            padding: 0;
            margin-left: 4px;
          }
        }
      }
    }

    .successsCase-container {
      .title {
        font-size: 15px;

        h1 {
          font-size: 20px;
        }

      }

      .content {
        width: 80%;
        margin-top: 20px;

        .item {
          width: 100%;

          .item-right {
            width: 100%;

            div:nth-child(1) {
              font-size: 15px;
            }

            div:nth-child(2) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .news-container {
      .title {
        h1 {
          font-size: 20px;
        }
      }

      .news {
        margin-top: 20px;
      }

      .page>span:nth-child(1) {
        font-size: 10px;
        margin: -10px 0 0 -130px;
        display: inline-block;
        width: 50px;
      }

      .page>span:nth-child(2) {
        font-size: 10px;
        margin: -10px 0 0 -80px;
        display: inline-block;
        width: 50px;
      }

      .companyItem {
        width: 80%;
        padding: 10px;
        margin-left: 10%;
        display: flex;
        flex-direction: column;

        h4 {
          font-size: 15px;
        }

        img {
          display: none;
        }

        >div {
          width: 100%;
          margin-right: 0;

          >div {
            div {
              font-size: 10px;
            }
          }
        }

        .firstNews {}
      }
    }

    .product-container {
      .title {
        h1 {
          font-size: 20px;
        }
      }

      .sort {
        div {
          padding: 2px;
          font-size: 10px;
        }
      }

      .item {
        div {
          width: 100%;

          img {}
        }
      }
    }

    .about-container {
      h1 {
        font-size: 20px;
        margin: 10px 0;
      }

      .intro {
        font-size: 10px;
      }

      .checkRight {
        left: 86%;
        z-index: 10;
      }

      .checkLeft {
        z-index: 10;
      }
    }

    .footer {
      .container {
        h1 {
          font-size: 20px;
        }
      }

      .woodFooter {
        margin-left: 1%;

        img {
          width: 80px;
        }

      }

      // .woodFooter .footer_left {
      //   float: left;
      // }
      .widget-content {
        // margin-left: 80px;
        margin-top: 30px;
        font-size: 10px;
        // margin-bottom: 15px;
      }
    }

    .subfooter {
      font-size: 10px;
    }
  }

  body::-webkit-scrollbar {
    width: 10px;
  }

  body::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
  }

  body::-webkit-scrollbar-thumb {
    background: #3B64BA;
  }

  body::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  body::-webkit-scrollbar-corner {
    background: #5db7eb;
  }
</style>
