<template>
  <div class="container">
    <div class="header-container" ref="home">
      <div class="banner" ref="banner" @mouseenter="enterBanner" @mouseleave="leaveBanner">
        <!-- 左侧按钮 -->
        <a href="javascript:;" class="arrowl" ref="arrowl" :style="{display:datashow}">
          &lt;
        </a>
        <!-- 右侧按钮 -->
        <a href="javascript:;" class="arrowr" ref="arrowr" :style="{display:datashow}"> &gt; </a>
        <!-- 核心的滚动区域 -->
        <ul ref="ul">
          <li>
            <a href="#"><img src="../static/images/banner.png" alt=""></a>
          </li>
          <!-- <li>
            <a href="#"><img src="../static/images/aboutUs.png" alt=""></a>
          </li>
          <li>
            <a href="#"><img src="../static/images/placeholder.jpg" alt=""></a>
          </li> -->
          <!-- <li ref="li" v-for="(item,index) in allPics" :key="index"><img :src="item"></li> -->
        </ul>
        <!-- 小圆圈是根据图片张数动态生成 -->
        <ol class="circle" ref="circle">

        </ol>
        <el-row class="header">
          <el-col :xs="24" :sm="10" :md="4" :lg="3" :xl="1">
            <div class="logo">
              <img src="../static/images/logo.png" alt="" />
            </div>
          </el-col>
          <el-col>
            <div class="header-right">
              <div @click="goAnchor('home', 50)" :class="[headerType == 'home' ? 'active' : '']">
                首页
              </div>
              <div @click="goAnchor('product', 50)" :class="[headerType == 'product' ? 'active' : '']">
                产品展示
              </div>
              <div @click="goAnchor('successCase', 50)" :class="[headerType == 'successCase' ? 'active' : '']">
                成功案例
              </div>
              <div @click="goAnchor('news', 50)" :class="[headerType == 'news' ? 'active' : '']">
                新闻资讯
              </div>
              <div @click="goAnchor('about', 50)" :class="[headerType == 'about' ? 'active' : '']">
                关于我们
              </div>
              <div @click="goAnchor('contact', 50)" :class="[headerType == 'contact' ? 'active' : '']">
                联系我们
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="subTitle">
      <!--<div v-if="headerType == 'successCase' && info.title.length === 4" class="left"></div>-->
      <!--<div v-if="headerType == 'successCase' && info.title.length === 6" class="six_left"></div>-->
        <!--<div v-if="headerType == 'successCase'" class=""></div>-->
        <div v-if="headerType == 'successCase'" style="margin: 0 auto;height: 40px; line-height: 40px;font-size: 24px;font-weight: bold;">{{info.title}}</div>
        <!--<div v-if="headerType == 'successCase'" class=""></div>-->
      <!--<div v-if="headerType == 'successCase' && info.title.length === 6" class="six_right"></div>-->
      <!--<div v-if="headerType == 'successCase' && info.title.length === 5" class="five_right"></div>-->
      <div class="newsTitle" v-if="headerType == 'news'">{{info.title}}</div>
    </div>
    <div class="authorTime">
      <div>
        <div style="display:inline;margin-right:30px">{{info.author}}</div>
        <div style="display:inline">{{info.date}}</div>
        <hr style="margin: 10px auto;border:1px dashed  #eee; width: 1600px"/>
      </div>
    </div>
    <div v-if="info.format == 2 && headerType == 'news'" class="iClass">
      <iframe :src="info.content" frameborder="0" width="101%" height="1200"></iframe>
    </div>
    <div v-html="info.content" class="content" v-else></div>
    <footer ref="contact" class="footer">
      <div class="footer_left">
        <div class="footer_left_logo">
          <img src="../static/images/logo.png" alt="" style="margin-top:67px">
        </div>
        <div class="footer_left_add">
          <p class="contacts" style="padding-bottom: 15px;">
            <i class="fa fa-map-marker"></i>地址：&nbsp;&nbsp;&nbsp;&nbsp;成都市高新区天府二街复城国际T1-18F
          </p>
          <p class="contacts" style="padding-bottom: 15px;">
            <i class="fa fa-phone"></i> 电话：&nbsp;&nbsp;&nbsp;&nbsp;028-60102873
          </p>
          <p class="contacts" style="padding-bottom: 15px;">
            <i class="fa fa-desktop"></i> 网址：&nbsp;&nbsp;&nbsp;&nbsp;https://www.schaxk.com
          </p>
          <p class="contacts">
            <i class="fa fa-yelp"></i>传真： &nbsp;&nbsp;&nbsp;&nbsp;028-60102875
          </p>
        </div>
      </div>
      <div class="footer_right">
        <p class="copyright">
          版权公告 © 2019-2022 四川慧安信科科技有限公司版权所有
        </p>
        <p class="copyright">
          <a style="color:#53f3f3" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20011909号-1</a>   <a style="color:#53f3f3" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20011909号-2</a>
        </p>
        <p class="copyright">
          川公网安备 51019002002737号
        </p>
      </div>
    </footer>
    <div class="el-backtop" style="right: 40px; bottom: 100px;" @click="back">
      <div class="back">返回</div>
    </div>


  </div>
</template>
<script>
  import animate from "../utils/animate";
  export default {
    name: 'Detail',
    data() {
      return {
        datashow: '',
        info: '',
        headerType: "",
      }
    },
    metaInfo(){
      return {
        title:this.info.title,
        meta: [
          { vmid: 'description', name: 'description', content:this.info.introduce }
        ]
      }
    },
    mounted() {
      // window.addEventListener("scroll", this.handleScroll);
      // const check = this.$refs['check'];
      const banner = this.$refs['banner'];
      // 通过ref属性获取到 ul和ol属性
      const ul = this.$refs['ul'];
      const ol = this.$refs['circle'];
      const arrowr = this.$refs['arrowr'];
      const arrowl = this.$refs['arrowl'];
      for (var i = 0; i < ul.children.length; i++) {
        var li = document.createElement('li');
        li.setAttribute('index', i);
        ol.appendChild(li);
        ol.children[0].className = 'current';
        li.addEventListener('click', function () {
          for (var i = 0; i < ol.children.length; i++) {
            ol.children[i].className = '';
          }
          this.className = 'current';
          var index = this.getAttribute('index');
          num = index;
          circle = index;
          var bannerWidth = banner.offsetWidth;
          animate(ul, -index * bannerWidth);
        })
      }
      var first = ul.children[0].cloneNode(true);
      ul.appendChild(first);
      var num = 0;
      var circle = 0;
      // var flag = true;
      arrowr.addEventListener('click', function () {
        // flag = false;
        if (num == ul.children.length - 1) {
          ul.style.left = 0;
          num = 0;
        }
        num++;
        var bannerWidth = banner.offsetWidth;
        animate(ul, -num * bannerWidth);
        circle++;
        if (circle == ol.children.length) {
          circle = 0;
        }
        for (var i = 0; i < ol.children.length; i++) {
          ol.children[i].className = '';
        }
        ol.children[circle].className = 'current';

      });
      arrowl.addEventListener('click', function () {
        var bannerWidth = banner.offsetWidth;
        if (num == 0) {
          num = ul.children.length - 1;
          ul.style.left = -num * bannerWidth + 'px';
        }
        num--;
        animate(ul, -num * bannerWidth);
        circle--;
        if (circle < 0) {
          circle = ol.children.length - 1;
        }
        for (var i = 0; i < ol.children.length; i++) {
          ol.children[i].className = '';
        }
        ol.children[circle].className = 'current';
      });
      if(ul.children.length>2){
        var timer = setInterval(function () { // eslint-disable-line no-unused-vars
        arrowr.click();
      }, 5000);
      }
    },
    watch: {
      info() {
        document.documentElement.scrollTop = 0;
      }
    },
    created() {
      this.info = this.$route.params.info;
      // console.log(this.$route.params.info);
      if (!this.info) {
        this.$router.replace('/')
      }
      this.headerType = this.$route.params.sign;
      window.scrollTo(0, document.body.scrollHeight);
    },
    methods: {
      goAnchor(sign) {
        this.$router.replace('/')
        this.$store.commit('CHANGESIGN', sign)
        // this.$emit('showMain')
      },
      back() {
        this.goAnchor(this.headerType)
      },
      enterBanner() {
        clearInterval(this.intervalId, this.timer);
        this.datashow = "block";
        this.timer = null;
      },
      leaveBanner() {
        clearInterval(this.intervalId);
        this.datashow = "none";
      },
    }
  }
</script>
<style lang="scss" scoped>
  * {
    // overflow: hidden;
  }

  .container {
    overflow-x: hidden;
    max-width: 1920px;
    margin: 0 auto;
  }

  .header-container {
    overflow: hidden;
  }

  .banner {
    position: relative;

    top: 0 img {
      width: 100%;
    }

    .title {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      font-weight: 600;
    }

    .header {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      height: 4%;
      display: flex;
      align-items: center;
      background-color: #5DB7EB;
      height: 80px;
      z-index: 10;

      .header-right {
        margin-left: 463px;
        display: flex;
        justify-content: center;
        font-size: 20px;

        >div {
          background-color: transparent;
          color: #fff;

          padding: 10px 20px;
        }

        >div:hover {
          cursor: pointer;
          color: #fff;
          font-weight: bold;
          text-shadow: 0 0 15px rgba(255, 255, 255, 1);
        }
      }

      .logo {
        display: flex;
        justify-content: center;

        img {}
      }
    }
  }

  .subTitle {
    position: relative;
    margin-top: 54px;
    /*margin-bottom: 20px;*/
    text-align: center;

    .left {
      position: absolute;
      left: 798px;
      top: 25px;
      width: 60px;
      height: 2px;
      background-color: #3A5EA9;
    }

    .six_left {
      position: absolute;
      left: 768px;
      top: 25px;
      width: 60px;
      height: 2px;
      background-color: #3A5EA9;
    }

    .five_left {
      position: absolute;
      left: 777px;
      top: 25px;
      width: 60px;
      height: 2px;
      background-color: #3A5EA9;
    }

    .right {
      position: absolute;
      left: 1050px;
      top: 25px;
      width: 60px;
      height: 2px;
      background-color: #3A5EA9;
    }

    .six_right {
      position: absolute;
      left: 1084px;
      top: 25px;
      width: 60px;
      height: 2px;
      background-color: #3A5EA9;
    }

    .five_right {
      position: absolute;
      left: 1070px;
      top: 25px;
      width: 60px;
      height: 2px;
      background-color: #3A5EA9;
    }

    .newsTitle {
      font-size: 22px;
      color: #333333;
      font-weight: 700;
    }
  }

  .content {
    width: 70%;
    margin: 0 auto;
    text-align: justify;
  }

  // 底部样式
  .footer {
    margin-top: 121px;
    width: 1920px;
    height: 302px;
    background-image: url("../static/images/footer.png");

    .footer_left {
      float: left;
      margin-left: 578px;
    }

    .footer_left_add {
      width: 333px;
      margin-top: 35px;
      color: #fff;
      font-size: 14px;
      border-right: 1px solid #fff;
    }

    .footer_right {
      float: right;
      margin-top: 150px;
      margin-right: 581px;
      color: #fff;
      font-size: 14px;

      .copyright {
        margin-bottom: 20px;
      }
    }
  }

  .woodFooter {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15%;
    padding: 20px;
  }

  .woodFooter img {
    width: 200px;
  }

  .footer h1 {
    color: #fff;
  }

  .footer.section {
    background: #5db7eb;
    color: #949494;
  }

  .subfooter {
    background-color: #5db7eb;
    padding: 10px 0 20px 0;
    text-align: center;
    color: #fff;
  }

  .widget-content {
    color: #fff;
  }

  .section {}

  .active {
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0 15px rgba(255, 255, 255, 1);
  }

  @media(max-width: 400px) {
    .header-container {
      .banner {
        height: 1px;

        .title {
          font-size: 20px;
        }

      }

      .header {
        .logo {
          width: 70%;
          margin-left: 0;

          img {
            width: 60%;

          }


        }

        .header-right {

          font-size: 10px;
          margin-left: -1px;
          width: 100%;

          >div {
            padding: 0;
            margin-left: 4px;
          }
        }
      }


    }

    .footer {
      .container {
        h1 {
          font-size: 20px;
        }
      }

      .woodFooter {
        margin: 0;
        width: 100%;

        img {
          width: 250px;
        }
      }

      .widget-content {

        font-size: 10px;


      }
    }

    .subfooter {
      font-size: 10px;
    }

  }

  .iClass {


    width: 100%;

  }

  .authorTime {
    text-align: center;
    width: 1920px;
    margin-bottom: 50px;
    // margin: 0 auto;
    font-size: 16px;
    color: #666666;
    margin-top:16px;
  }

  .back {
    width: 100%;
    background-color: rgb(242, 245, 246);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 6px;
    text-align: center;
    line-height: 40px;
    color: rgb(25, 137, 250);
    font-size: 13px;
    border-radius: 50%;
  }
</style>
