import axios from "axios";

import baseurl from "./config";

function request(url, type = 'GET', data = {}) {
    const promise = new Promise((resolve, reject) => {
        axios({
            method: type,
            url: baseurl + url,
            data,
        }).then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)

        })
    })

    return promise
}
export default request;