<template>
  <div id="app" >
<keep-alive exclude="Detail">
<router-view></router-view>
</keep-alive>
    
    
  
  </div>
</template>

<script>


export default {
  name: 'App',
  data(){
    return{
      showDetail:false,
      item:'',
      sign:''
    }
  },
  methods:{
    openDetail(item,sign){
      ('pa',item,sign)
      this.item = item;
      this.sign =sign;
      this.showDetail = true;
    },
    showMain(){
      this.showDetail = false;
    }
  }
}
</script>

<style>
 #app{
   overflow-x: hidden;
 }
</style>
