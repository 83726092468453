import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animated from 'animate.css'
import router from "./utils/route.js"
import store from "./store/store"
import "./css/animate.css";
import "./css/animations.css"
import Meta from "vue-meta"


import App from './App.vue'



Vue.use(animated)

Vue.use(ElementUI);
Vue.use(Meta)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
