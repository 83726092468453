
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
    state:{
        sign:'home'
    },
    mutations:{
        CHANGESIGN(state,sign){
            state.sign = sign;
        }
    }
})
export default store;