<template>
  <div class="container">
    <!-- 请求完成前的加载效果 -->
    <div v-show="!loading">
      <div class="header-container" ref="home">
        <!-- 首页图片/标题切换 -->
        <div class="banner" ref="banner" @mouseenter="enterBanner" @mouseleave="leaveBanner">
          <!-- 左侧按钮 -->
          <a href="javascript:;" class="arrowl" ref="arrowl" :style="{display:datashow}">
            &lt;
          </a>
          <!-- 右侧按钮 -->
          <a href="javascript:;" class="arrowr" ref="arrowr" :style="{display:datashow}"> &gt; </a>
          <!-- 核心的滚动区域 -->
          <ul ref="ul">
            <li>
              <a href="#"><img src="../static/images/banner.png" alt=""></a>
            </li>
            <!-- <li>
              <a href="#"><img src="../static/images/aboutUs.png" alt=""></a>
            </li>
            <li>
              <a href="#"><img src="../static/images/placeholder.jpg" alt=""></a>
            </li> -->
          </ul>
          <!-- 小圆圈是根据图片张数动态生成 -->
          <ol class="circle" ref="circle">

          </ol>
          <!-- logo部分 -->
          <div class="header" ref="header">
            <div class="header-top">
              <el-row :class="[isScroll ? 'header  scroll ' : 'header']">
                <!-- 顶部导航栏 -->
                <el-col>
                  <div class="header-right">
                    <div class="logo">
                      <img style="margin-right:710px" src="../static/images/logo.png" alt="" />
                    </div>
                    <div class="div" @click="goAnchor('home', 50)" :class="[headerType == 'home' ? 'active' : '']">
                      首页
                    </div>
                    <div class="div" @click="goAnchor('successCase', 50)"
                      :class="[headerType == 'successCase' ? 'active' : '']">
                      成功案例
                    </div>
                    <div class="div" @click="goAnchor('product', 50)"
                      :class="[headerType == 'product' ? 'active' : '']">
                      产品展示
                    </div>
                    <div class="div" @click="goAnchor('about', 50)" :class="[headerType == 'about' ? 'active' : '']">
                      关于我们
                    </div>
                    <div class="div" @click="goAnchor('news', 50)" :class="[headerType == 'news' ? 'active' : '']">
                      新闻资讯
                    </div>
                    <div class="div" @click="goAnchor('contact', 50)"
                      :class="[headerType == 'contact' ? 'active' : '']">
                      联系我们
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <!-- 成功案例 -->
      <div class="successsCase-container" ref="successCase" :class="successCaseAni">
        <div class="title">
          <h1>成功案例</h1>
          <div class="success_rectangle"></div>
        </div>
        <div class="success_box">
          <div class="item" v-for="(item, index) in caseList" :key="index">
            <div class="item-mask" @click="openDetail(item, 'successCase')"></div>
            <img :src="item.icon" alt="">
            <!-- <div class="item_more" v-show="index == 7" @click="openAllItem(index)">
              点击更多
              <img src="../static/images/more.png" alt="" style="width:13px;height:14px">
            </div> -->
            <div class="item-right" @click="openDetail(item, 'successCase')">
              <div>{{ item.title }}</div>
              <div class="item_introduce">{{ item.introduce }}</div>
            </div>
          </div>
          <div></div>
          </div>
      </div>
      <!-- 联系我们 -->
      <footer ref="contact" class="footer">
        <div class="footer_left">
          <div class="footer_left_logo">
            <img src="../static/images/logo.png" alt="" style="margin-top:67px">
          </div>
          <div class="footer_left_add">
            <p class="contacts" style="padding-bottom: 15px;">
              <i class="fa fa-map-marker"></i>
              地址：&nbsp;&nbsp;&nbsp;&nbsp;成都市高新区天府二街复城国际T1-18F
            </p>
            <p class="contacts" style="padding-bottom: 15px;">
              <i class="fa fa-phone"></i> 电话：&nbsp;&nbsp;&nbsp;&nbsp;028-60102873
            </p>
            <p class="contacts" style="padding-bottom: 15px;">
              <i class="fa fa-desktop"></i> 网址：&nbsp;&nbsp;&nbsp;&nbsp;https://www.schaxk.com
            </p>
            <p class="contacts">
              <i class="fa fa-yelp"></i>传真： &nbsp;&nbsp;&nbsp;&nbsp;028-60102875
            </p>
          </div>
        </div>
        <div class="footer_right">
          <p class="copyright">
            版权公告 2019-2020 四川慧安信科科技有限公司版权所有
          </p>
          <p class="copyright">
            <a style="color:#53f3f3" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20011909号-1</a>   <a style="color:#53f3f3" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备20011909号-2</a>
          </p>
          <p class="copyright">
            川公网安备 51019002002737号
          </p>
        </div>
      </footer>

    </div>

    <!-- loading动画 -->
    <div class="loading" :style="{ height: clientHeight + 'px', 'min-height': '800px' }" v-if="loading">
      <!-- <img src="../static/images/01.gif" alt="" /> -->
      <div class="spinner">
        <div class="spinner-container container1">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
        </div>
        <div class="spinner-container container2">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
        </div>
        <div class="spinner-container container3">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import request from "../utils/request";
  import baseurl from "../utils/config";
  import animate from "../utils/animate";
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        showCheck: false, //关于我们图片切换按钮
        successCaseAni: "", //成功案例动画
        loading: true, //加载完成之前显示动画
        headerType: "home", //顶部导航栏类别，切换active状态
        isScroll: false, //顶部导航栏滑动显示背景
        clientHeight: "", //界面高度，定义容器高度
        bannerSrc: [], //轮播图数据
        caseList: [], //成功案例数据
        initCaseList: [], //全部案例
        index: 0, //轮播图相关
        intervalId: "", //轮播图循环定时器
        // timer: "",
        bannerAni: "", //轮播图动画
        titleAni: "", //轮播图标题
        title: [
          "四川慧安信科科技有限公司",
          "城市治理因我们而智慧",
          "政务服务因我们而贴心",
        ], //标题
        scrollTop: 0,
        nowIntroduceIndex: 0,

        have: {
          haveSuccesssCase: false
        }

      };
    },
    metaInfo(){
      return {
        title:this.caseList.title,
        meta: [
          { vmid: 'description', name: 'description', content:this.caseList.introduce }
        ]
      }
    },
    //挂载前获得屏幕高度
    beforeMount() {
      this.clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
    },
    //挂载时监听scroll事件
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
      // const check = this.$refs['check'];
      const banner = this.$refs['banner'];
      // 通过ref属性获取到 ul和ol属性
      const ul = this.$refs['ul'];
      const ol = this.$refs['circle'];
      const arrowr = this.$refs['arrowr'];
      const arrowl = this.$refs['arrowl'];
      for (var i = 0; i < ul.children.length; i++) {
        var li = document.createElement('li');
        li.setAttribute('index', i);
        ol.appendChild(li);
        ol.children[0].className = 'current';
        li.addEventListener('click', function () {
          for (var i = 0; i < ol.children.length; i++) {
            ol.children[i].className = '';
          }
          this.className = 'current';
          var index = this.getAttribute('index');
          num = index;
          circle = index;
          var bannerWidth = banner.offsetWidth;
          animate(ul, -index * bannerWidth);
        })
      }
      var first = ul.children[0].cloneNode(true);
      ul.appendChild(first);
      var num = 0;
      var circle = 0;
      // var flag = true;
      arrowr.addEventListener('click', function () {


        // flag = false;
        if (num == ul.children.length - 1) {
          ul.style.left = 0;
          num = 0;
        }
        num++;
        var bannerWidth = banner.offsetWidth;
        animate(ul, -num * bannerWidth);
        circle++;
        if (circle == ol.children.length) {
          circle = 0;
        }
        for (var i = 0; i < ol.children.length; i++) {
          ol.children[i].className = '';
        }
        ol.children[circle].className = 'current';

      });
      arrowl.addEventListener('click', function () {
        if (num == 0) {
          num = ul.children.length - 1;
          ul.style.left = -num * bannerWidth + 'px';

        }
        num--;
        var bannerWidth = banner.offsetWidth;
        animate(ul, -num * bannerWidth);
        circle--;
        if (circle < 0) {
          circle = ol.children.length - 1;
        }
        for (var i = 0; i < ol.children.length; i++) {
          ol.children[i].className = '';
        }
        ol.children[circle].className = 'current';
      });
      if(ul.children.length>2){
        var timer = setInterval(function () { // eslint-disable-line no-unused-vars
        arrowr.click();
      }, 5000);
      }
    },
    computed: mapState({
      sign(state) {
        return state.sign;
      },
    }),
    watch: {
      sign(val) {
        window.addEventListener("scroll", this.handleScroll);
        this.goAnchor(val, 50);
      },
    },
    created() {
      this.loading = true;
      this.getData();
      this.interAbout();
      this.have.haveSuccesssCase ? '' : this.getSuccessCase();
    },
    destroyed() {
      // 离开该页面需要移除这个监听的事件，不然会报错
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      goAnchor(sign) {
        this.$router.replace('/')
        this.$store.commit('CHANGESIGN', sign)
        // this.$emit('showMain')
      },
      back() {
        this.goAnchor(this.headerType)
      },
      async getTeam() {
        this.have.haveTeam = true;
        const data = await request("/official/us/getUsDisplay");
        // ("data", data);
        this.about = data.data.data.officialUs.intro;
        this.team = data.data.data.officialUsPicList.map((item) => {
          return {
            avator: baseurl + item.pic,
          };
        });
      },
      handleScroll() {
        try {
          this.scrollTop =
            (window.pageYOffset ||
              document.documentElement.scrollTop ||
              document.body.scrollTop) + this.$refs["header"].offsetHeight;
          if (this.scrollTop > 200) {
            this.isScroll = true;
          } else {
            this.isScroll = false;
          }
          let contact = this.$refs["contact"].offsetTop; //模块相对于内容顶部的距离
          if (this.scrollTop < 110) {
            this.headerType = "home";
            this.have.haveSuccesssCase ? '' : this.getSuccessCase()
            // this.successCaseAni = "animated fadeInRight";
          } else if (this.scrollTop > 110 && this.scrollTop < 1000) {
            this.headerType = "successCase";
          } else if (this.scrollTop > 1000 && this.scrollTop < 1600) {
            if (!this.have.haveNews) {
              this.getCompanyNews()
              this.getBusinessNews()
            }
            this.headerType = "news";
            this.newsAni = "animated fadeInLeft";
          } else if (this.scrollTop > 1700 && this.scrollTop < 2425) {
            if (!this.have.haveProduct) {
              this.getType()
              this.getProduct()
            }

            this.productAni = "animated fadeInDown";
            this.headerType = "product";
          } else if (this.scrollTop > 2425) {
            if (!this.have.haveTeam) {
              this.getTeam()
            }

            this.aboutAni = "animated fadeInUp";

            this.headerType = "about";
          } else if (this.scrollTop > contact - 50) {
            this.headerType = "contact";
          }
        } catch (e) {
          return;
        }
      },
      imgReady(pics) {
        const picsAll = pics.map((imgUrl) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imgUrl;
            img.onload = () => resolve(imgUrl);
            img.onerror = () => reject(new Error(imgUrl + "error"));
          });
        });
        Promise.all(picsAll)
          .then(() => {
            this.bannerSrc = pics;

            this.bannerAni = "animated big";
            this.titleAni = "animated bounceOutDown";
            this.inter();

          })
          .catch((e) => {
            (e);
            this.loading = false;
          });
      },
      async getData() {
        try {
          const bannerData = await request("/official/slideshow/listDisplay");

          const imgs = bannerData.data.rows.map((item) => {
            return baseurl + item.imgUrl;
          });
          this.loading = false;

          this.imgReady(imgs);
        } catch (err) {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "系统错误，请稍后重试",
            type: "error",
          });
          ("err", err);
        }
      },
      async getSuccessCase() {
        this.have.haveSuccesssCase = true;
        const data = await request("/official/success/listDisplay");
        this.caseList = data.data.rows.map((item) => {
          const obj = {
            icon: baseurl + item.mainPic,
            title: item.title,
            introduce: item.intro,
            content: item.content,
          };
          return obj;
        });
        //  this.initCaseList = this.caseList.concat();
        //   this.caseList = this.caseList.slice(0, 7);
        //   this.caseList.push({
        //     icon: "http://localhost/dev-api/profile/upload/2021/06/25/95a536bd-c8df-4489-8fe8-326f6049ad44.png",
        //     title: "",
        //     introduce: '',
        //   });
      },
      openDetail(info, sign) {
        // if (index === 7) {
        //   this.caseList = this.initCaseList;
        //   console.log(this.caseList)
        // }
        window.removeEventListener("scroll", this.handleScroll);
        if (info.content.indexOf("mp.weixin.qq.com") > -1) {
          window.open(info.content);
          return;
        }
        this.$router.push({
          name: "Detail",
          params: {
            info,
            sign
          }
        });
      },
      enterBanner() {
        clearInterval(this.intervalId, this.timer);
        this.datashow = "block";
        this.bannerAni = "animated small";
        this.titleAni = "animated bounceInDown";
        // clearInterval(timer);
        this.timer = null;
      },
      leaveBanner() {
        clearInterval(this.intervalId);
        this.datashow = "none";
        this.bannerAni = "animated big";
        this.titleAni = "animated bounceOutDown";
        this.inter();
      },
      inter() {
        this.intervalId = setInterval(() => {
          this.index++;
          this.bannerAni = "";
          this.titleAni = "";

          this.$nextTick(() => {
            this.bannerAni = "animated big";
            this.titleAni = "animated bounceOutDown";
          });
        }, 4000);
      },

      async getCompanyNews() {
        this.have.haveNews = true;
        const data = await request("/official/news/listDisplay");
        this.companyNews = data.data.rows.map((item) => {
          const obj = {
            title: item.title,
            introduce: item.intro,
            content: item.content,
            format: item.format,
            imgSrc: baseurl + item.mainPic,
            author: item.author,
            date: item.createTime.substr(0, 11),
          };
          return obj;
        });
        this.totalNumber = this.companyNews.length;
        this.totalNumber % this.pageSize != 0 ?
          (this.totalPage = parseInt(this.totalNumber / this.pageSize) + 1) :
          (this.totalPage = parseInt(this.totalNumber / this.pageSize));
        this.nowNews = this.companyNews.slice(
          this.nowPage - 1,
          this.pageSize * this.nowPage
        );
      },
      async getBusinessNews() {
        const data = await request("/official/dynamics/listDisplay");
        this.businessNews = data.data.rows.map((item) => {
          const obj = {
            title: item.title,
            introduce: item.intro,
            content: item.content,
            imgSrc: baseurl + item.mainPic,
            format: item.format,
            author: item.author,
            date: item.createTime.substr(0, 11),
          };
          return obj;
        });
      },
      async getProduct() {

        const data = await request("/official/product/listDisplay");
        this.allProduct = data.data.rows.map((item) => {
          const obj = {
            sign: item.productId,
            title: item.title,
            introduce: item.intro,
            content: item.content,
            imgSrc: baseurl + item.mainPic,
          };
          return obj;
        });
        this.product = this.allProduct;
      },
      filterProduct(item, index) {
        if (item.filter == "all") {
          this.product = this.allProduct;
          // this.class(.on):addClass("on");
        } else {
          this.productIndex = index;
          let temp = JSON.parse(JSON.stringify(this.allProduct));
          this.product = [];
          temp.map((c) => {
            if (c.sign == item.filter) {
              this.product.push(c);
            }
          });
        }
      },
      async getType() {
        this.have.haveProduct = true;
        const data = await request("/official/productCategory/listDisplay");
        this.classify = data.data.rows.map((item) => {
          const obj = {
            name: item.categoryName,
            filter: item.id,
          };
          return obj;
        });
        this.classify.unshift({
          name: "全部",
          filter: "all",
        });
      },
      enter(type) {
        this.nowPage = 1;
        if (type == 0) {
          this.nowNews = this.companyNews.slice(
            this.nowPage - 1,
            this.pageSize * this.nowPage
          );
          this.newsType = 0;
          this.totalNumber = this.companyNews.length;
        } else {
          this.nowNews = this.businessNews.slice(
            this.nowPage - 1,
            this.pageSize * this.nowPage
          );
          this.newsType = 1;
          this.totalNumber = this.businessNews.length;
        }
        this.totalNumber % this.pageSize != 0 ?
          (this.totalPage = parseInt(this.totalNumber / this.pageSize) + 1) :
          (this.totalPage = parseInt(this.totalNumber / this.pageSize));
      },
      nextPage() {
        this.nowPage++;
        if (this.newsType == 0) {
          this.nowNews = this.companyNews.slice(
            (this.nowPage - 1) * this.pageSize,
            this.pageSize * this.nowPage
          );
        } else {
          this.nowNews = this.businessNews.slice(
            (this.nowPage - 1) * this.pageSize,
            this.pageSize * this.nowPage
          );
        }
      },
      prevPage() {
        this.nowPage--;
        if (this.newsType == 0) {
          this.nowNews = this.companyNews.slice(
            (this.nowPage - 1) * this.pageSize,
            this.pageSize * this.nowPage
          );
        } else {
          this.nowNews = this.businessNews.slice(
            (this.nowPage - 1) * this.pageSize,
            this.pageSize * this.nowPage
          );
        }
      },


      enterAbout() {
        this.showCheck = true;
        clearInterval(this.intervalIdAbout);
      },

      leaveAbout() {
        clearInterval(this.intervalIdAbout);
        setTimeout(() => {
          this.interAbout();
        }, 2000);

        this.showCheck = false;
      },
      enterProduct(index) {
        if (/Mobile|Android|webOS|iPhone|iPad|Phone/i.test(navigator.userAgent)) {
          return;
        }
        this.nowIntroduceIndex = index;
      },
      leaveProduct() {
        this.nowIntroduceIndex = 0;
      },
    },
  };
</script>
<style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
  }

  .container {
    overflow-x: hidden;
    max-width: 1920px;
    margin: 0 auto;
    // position: relative;
    // top: 0;
    // left: 0;
    // transform: translate(-0,-0);
  }

  // banner部分
  .header-container {
    overflow: hidden;
    width: 1920px;
    height: 618px;

    // background-color: pink;
    .banner {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      a {
        text-decoration: none;
      }

      li {
        list-style: none;
      }

      .arrowl,
      .arrowr {
        display: none;
        position: absolute;
        top: 50%;
        margin-top: -20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: rgba(0, 0, 0, .3);
        text-align: center;
        color: #fff;
        border-radius: 50%;
        font-family: '宋体';
        z-index: 2;
      }

      .arrowr {
        right: 0;
      }

      ul {
        position: absolute;
        top: 0;
        left: 0;
        width: 800%;
      }

      ul li {
        float: left;
      }

      .circle {
        position: absolute;
        bottom: 10px;
        left: 50%;
      }

      .circle li {
        float: left;
        width: 15px;
        height: 15px;
        /*background-color: #fff;*/
        border: 1px solid rgba(113, 141, 202, 0.5);
        margin: 0 3px;
        border-radius: 50%;
        /*鼠标经过显示小手*/
        cursor: pointer;
      }

      .current {
        background-color: #fff;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .banner {
    position: relative;

    img {
      width: 100%;
    }

    .title {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      font-weight: 600;
    }

    .header {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      height: 4%;
      display: flex;
      align-items: center;
      background-color: transparent;
      height: 80px;
      z-index: 10;

      .header-top {
        position: relative;
        width: 1920px;
        height: 100%;
        margin: 0 auto;
      }

      .header-right {
        // margin-left: 455px;
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;

        .div:hover {
          border-bottom: 1px solid #4799c9;
        }

        >div {
          background-color: transparent;
          color: #fff;
          padding: 10px 20px;
        }

        >div:hover {
          cursor: pointer;
          color: #fff;
          font-weight: 900;
          text-shadow: 0 0 15px rgb(71, 153, 201);
          // border-bottom: 1px solid #4799c9;
        }
      }

      .logo {
        margin-left: 392px;
        display: flex;
        justify-content: center;
        width: 129px;
        height: 43px;
        // img {
        //   width: 126px;
        //   height: 26px;
        // }
      }
    }
  }

  .ani {
    animation: pulse;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 4s;
    /* don't forget to set a duration! */
  }

  .successsCase-container {
    position: relative;
    margin-top: 5%;
    width: 1920px;
    text-align: center;
    position: relative;

    .success_rectangle {
      width: 70px;
      height: 26px;
      background-color: #3B64BA;
      opacity: 0.7;
      position: absolute;
      left: 964px;
      top: 21px;
    }

    .success_box {
      width: 1211px;
      height: 1110px;
      overflow: hidden;
      margin: 60px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        position: relative;
        cursor: pointer;
        width: 284px;
        height: 347px;
        display: flex;
        margin-right: 18px;
        margin: 10px 5px;
      }

      .item:hover {
        display: block;
        border-radius: 6px;
        cursor: pointer;
        transform: scale(1.01);
        transition: all 0.5s ease;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
      }

      .item:hover .item-mask {
        height: 100%;
        transition: 500ms;
      }

      .item:hover .item-right {
        position: absolute;
        top: 40%;
      }

      .item_more {
        width: 100%;
        text-align: center;
        /*margin-top: 182px;*/
        line-height: 347px;
        color: #333333;
        font-size: 18px;
        font-family: 'Alibaba-PuHuiTi-M';
      }

      .item_more:hover {
        cursor: pointer;
      }

      .item img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        cursor: pointer;
      }

      .item-mask {
        position: absolute;
        bottom: 0px;
        //left: -1px;
        width: 100%;
        height: 87px;
        border-radius: 5px;
        cursor: pointer;
        background: rgba(0, 0, 0, .4);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: height 300ms;
        box-sizing: border-box;
      }

      .item-right {
        position: absolute;
        bottom: 0px;
        left: -1px;
        width: 100%;
        text-align: center;
        padding-top: 10px;

        >div:hover {
          cursor: pointer;
        }

        >div:nth-child(1) {
          color: #fff;
          font-size: 20px;
          font-family: 'Alibaba-PuHuiTi-M';
        }

        >div:nth-child(2) {
          margin-bottom: 15px;
          margin-top: 15px;
          color: #fff;
          font-size: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          -webkit-transition: top .2s ease-in;
          -moz-transition: top .2s ease-in;
          transition: top .2s ease-in;
        }
      }
    }
  }

  // 底部样式
  .footer {
    margin-top: 95px;
    width: 1920px;
    height: 302px;
    background-image: url("../static/images/footer.png");

    .footer_left {
      float: left;
      margin-left: 578px;
    }

    .footer_left_add {
      width: 333px;
      margin-top: 35px;
      color: #fff;
      font-size: 14px;
      border-right: 1px solid #fff;
    }

    .footer_right {
      float: right;
      margin-top: 150px;
      margin-right: 581px;
      color: #fff;
      font-size: 14px;

      .copyright {
        margin-bottom: 20px;
      }
    }
  }

  .section {
    padding: 20px;
  }

  .active {
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0 15px rgb(71, 153, 201);
  }

  .scroll {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: rgba(93, 183, 235) !important;
    height: 80px;
    z-index: 10;
  }

  .loading {
    width: 100%;
    background-color: #faf7ef;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
  }

  .spinner {
    width: 40px;
    height: 40px;
    position: relative;
  }

  .container1>div,
  .container2>div,
  .container3>div {
    width: 6px;
    height: 6px;
    background-color: #67cf22;

    border-radius: 100%;
    position: absolute;
    -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
    animation: bouncedelay 1.2s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .spinner .spinner-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .container2 {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }

  .container3 {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
  }

  .circle1 {
    top: 0;
    left: 0;
  }

  .circle2 {
    top: 0;
    right: 0;
  }

  .circle3 {
    right: 0;
    bottom: 0;
  }

  .circle4 {
    left: 0;
    bottom: 0;
  }

  .container2 .circle1 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .container3 .circle1 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .container1 .circle2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .container2 .circle2 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  .container3 .circle2 {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  .container1 .circle3 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  .container2 .circle3 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  .container3 .circle3 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  .container1 .circle4 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  .container2 .circle4 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }

  .container3 .circle4 {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes bouncedelay {

    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes bouncedelay {

    0%,
    80%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    40% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  @media (max-width: 400px) {
    // .successsCase-container .content .item-right>div:nth-child(2) {
    //   width: 100%;
    // }

    .header-container {
      width: 100%;

      .banner {
        height: 300px;

        .title {
          font-size: 20px;
        }

        img {
          width: 100%;
        }
      }

      .header {
        .logo {
          width: 70%;
          margin-left: 45%;

          img {
            width: 126px;
            height: 26px;
          }
        }

        .header-right {
          font-size: 10px;
          margin-left: -30px;
          width: 90%;

          >div {
            padding: 0;
            margin-left: 4px;
          }
        }
      }
    }

    .successsCase-container {
      .title {
        font-size: 15px;

        h1 {
          font-size: 20px;
        }

      }

      .content {
        width: 80%;
        margin-top: 20px;

        .item {
          width: 100%;

          .item-right {
            width: 100%;

            div:nth-child(1) {
              font-size: 15px;
            }

            div:nth-child(2) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .footer {
      .container {
        h1 {
          font-size: 20px;
        }
      }

      .woodFooter {
        margin-left: 1%;

        img {
          width: 80px;
        }

      }

      // .woodFooter .footer_left {
      //   float: left;
      // }
      .widget-content {
        // margin-left: 80px;
        margin-top: 30px;
        font-size: 10px;
        // margin-bottom: 15px;
      }
    }

    .subfooter {
      font-size: 10px;
    }
  }

  body::-webkit-scrollbar {

    width: 10px;

  }

  body::-webkit-scrollbar-track {

    background: #fff;

    border-radius: 2px;

  }

  body::-webkit-scrollbar-thumb {

    background: #3B64BA;

    // border-radius:10px;

  }

  body::-webkit-scrollbar-thumb:hover {

    background: #fff;

  }

  body::-webkit-scrollbar-corner {

    background: #5db7eb;

  }
</style>
